/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Form } from "@fas/ui-core";
import withForm, { type Props as FormProps } from "@fas/ui-framework/lib/services/form/withForm";
import {
  Box,
} from "@mui/material";
import { useDictionary } from "@fas/cpa-state-manager/redux/hooks";
import { withHookPropsState } from "@fas/cpa-cabinet-ui";
import {
  LockOutlined,
} from "@mui/icons-material";
import { Helmet } from "react-helmet";
import { withStyles } from "@mui/styles";
import type { ComponentProps } from "@fas/cpa-cabinet-ui/lib/withState/withState.types";
import { type UseDictionaryType } from "@fas/cpa-state-manager/redux/hooks/useDictionary/useDictionary";
import type { Dictionaries } from "@fas/cpa-state-manager/redux/reducers";
import { CONTACT_INFO_FORM } from "../../constants";
import TextField from "../TextFieldForm";
import { SelectPopupField, type Props as SelectProps } from "../SelectPopup/SelectPopup";
import ShowByValue from "../UserInfo/ShowByValue";
import Location from "../../icons/Location";
import Person from "../../icons/Person";
import Password from "../../icons/Password";
import { formGridStyles } from "../../styles";

const ContactInfoForm: StatelessFunctionalComponent<FormProps> = withForm(Form);

const CountrySelect: StatelessFunctionalComponent<
  ComponentProps<SelectProps, Dictionaries, UseDictionaryType>
> = withHookPropsState(SelectPopupField, useDictionary);

type Props = {
  isLoading: boolean,
};

const Layout = withStyles(formGridStyles)(({ children, classes }) => (
  <Box className={classes.root}>{children}</Box>
));

const ContactInfo: StatelessFunctionalComponent<Props> = () => {
  const tooltipIconProps = {
    EndIcon: <LockOutlined />,
    slotProps: {
      tooltip: {
        title: "For any updates in this field, please contact your account manager.",
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Contact info</title>
      </Helmet>
      <ContactInfoForm id={CONTACT_INFO_FORM}>
        <Layout>
          <TextField
            {...tooltipIconProps}
            name="companyType"
            label="I am"
            StartIcon={<Password />}
            disabled
            EndIcon={<LockOutlined />}
          />
          <ShowByValue name="companyType" compare={(companyType: string): boolean => !["Individual / Affiliate Team Representative", "Individual/Publisher"].includes(companyType)}>
            <TextField
              {...tooltipIconProps}
              name="companyName"
              label="Company Name"
              placeholder="What is your company name?"
              StartIcon={<Password />}
              disabled
              EndIcon={<LockOutlined />}
            />
            <TextField
              {...tooltipIconProps}
              name="companyUrl"
              label="Website"
              placeholder="What is your website URL?"
              StartIcon={<Person />}
              disabled
              EndIcon={<LockOutlined />}
            />
          </ShowByValue>
          <CountrySelect
            {...tooltipIconProps}
            name="country"
            label="Country"
            placeholder="Select your country"
            hookProps="countryId"
            StartIcon={<Location />}
            disabled
            EndIcon={<LockOutlined />}
            defaultValue=""
          />
          <Box className="colSpan2">
            <TextField
              {...tooltipIconProps}
              name="address"
              label="Address"
              placeholder="Your ZIP/postcode, city, st., bldg."
              StartIcon={<Location />}
              disabled
              EndIcon={<LockOutlined />}
            />
          </Box>
          <TextField
            {...tooltipIconProps}
            name="taxSSN"
            label="Tax SNN/EIN (USA only)"
            placeholder="What is your Tax SNN/EIN?"
            StartIcon={<Person />}
            disabled
          />
        </Layout>
      </ContactInfoForm>
    </>
  );
};

export default ContactInfo;
