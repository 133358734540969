// @flow
import { type DefaultTheme } from "../themes";

const autologin: (DefaultTheme) => * = (theme) => ({
  root: {
    width: "100%",
    maxWidth: "500px",
    display: "flex",
    flexDirection: "column",
    "& .MuiFormControl-root + .MuiFormControl-root": {
      marginTop: "20px",
      [theme.breakpoints.up("md")]: {
        marginTop: "28px",
      },
    },
  },
  title: {
    textAlign: "center",
    fontSize: "36px",
    lineHeight: "1.111",
    fontWeight: "700",
    marginBottom: "28px",
    color: theme.palette.text.main,
    [theme.breakpoints.up("md")]: {
      fontSize: "40px",
      lineHeight: "1.2",
      fontWeight: "800",
      marginBottom: "40px",
    },
  },
  button: {
    ...theme.typography.mainButton,
    margin: "20px auto 0",
    [theme.breakpoints.up("md")]: {
      marginTop: "40px",
      maxWidth: "250px",
    },
  },
});

export default autologin;
