/* eslint-disable import/max-dependencies */
// @flow
import {
  combineReducers,
  createStore,
  compose,
  applyMiddleware,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type {
  Action as NotificationsActions,
} from "@fas/ui-framework/lib/redux/actions/notifications";
import { initState as initErrorsState, reducer as errors } from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import type { State as ErrorsState } from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import type { Actions as ErrorsActions } from "@fas/ui-framework/lib/redux/actions/errors";
import loading, { type State as LoadingState, initLoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import { type Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import {
  USER_INFO,
  MANAGER_INFO,
  DASHBOARD_TABLE,
  EVENTS_POSTBACK,
  TRACKING_CODE_LOADING,
  BEST_OFFERS_LIST,
  AUTOLOGIN_FORM,
  LOGIN_FORM,
} from "@fas/cpa-state-manager/redux/constants";
import Immutable from "immutable";
import createSagaMiddleware, { type SagaMiddleware } from "redux-saga";
import type {
  ManagerInfoActions,
  UserInfoActions,
} from "@fas/cpa-state-manager/redux/actions";
import {
  managerInfoReducer as managerInfo,
  initManagerInfoState,
  userInfoReducer as userInfo,
  initUserInfoState,
  dictionariesReducer as dictionaries,
  initDictionariesState,
  type DictionariesState,
  eventsPostbackReducer as eventsPostback,
  type EventsPostbackState,
  initEventsPostbackState,
  trackingToolsReducer as trackingTools,
  initTrackingToolsState,
  type TrackingToolsState,
  tableReducer as tables,
  type TableState,
  initTableState,
  outstandingBalanceInfoReducer as outstandingBalanceInfo,
  initOutstandingBalanceInfoState,
  type OutstandingBalanceInfoState,
  type ManagerInfoState,
  type UserInfoState,
} from "@fas/cpa-state-manager/redux/reducers";
import tabs, { initTabsState, type State as TabsState } from "@fas/cpa-state-manager/redux/reducers/tabs";
import { type TabsActions } from "@fas/cpa-state-manager/redux/actions/tabs";
import form, {
  initFormState,
  type State as FormState,
} from "@fas/ui-framework/lib/redux/reducers/form";
import mainSaga from "./saga";
import {
  PROFILE_PAGE,
  USER_INFO_TAB,
  CONTACT_INFO_TAB,
  PAYMENT_METHODS_TAB,
  PAYMENT_METHOD_FORM,
  USER_INFO_FORM,
  CONTACT_INFO_FORM,
  REPORTS_PAGE,
  MAIN_REPORT_TAB,
  TRANSACTION_REPORT_TAB,
  MAIN_REPORT_TABLE,
  POSTBACKS_PAGE,
  DEFAULT_POSTBACK_TAB,
  INFO_POSTBACKS_TAB,
  PAYMENT_HISTORY_TABLE,
  TRANSACTION_REPORT_TABLE,
  INFO_MAIN_POPUP,
} from "../../constants";
import { defaultPreset } from "../../hooks/useChart/presets";
import { defaultPresetReport } from "../../components/Reports/presets";
import { defaultPaymentHistoryPreset } from "../../components/PaymentHistoryActions/presets";

export type LoadingKeys = typeof USER_INFO |
                          typeof MANAGER_INFO |
                          typeof CONTACT_INFO_FORM |
                          typeof PAYMENT_METHOD_FORM |
                          typeof EVENTS_POSTBACK |
                          typeof MAIN_REPORT_TABLE |
                          typeof TRACKING_CODE_LOADING |
                          typeof INFO_MAIN_POPUP |
                          typeof LOGIN_FORM |
                          typeof AUTOLOGIN_FORM;

export type State = $ReadOnly<{
  form: FormState,
  loading: LoadingState<LoadingKeys>,
  notifications: NotificationsState,
  errors: ErrorsState,
  userInfo: UserInfoState,
  managerInfo: ManagerInfoState,
  tabs: TabsState,
  dictionaries: DictionariesState,
  eventsPostback: EventsPostbackState,
  trackingTools: TrackingToolsState,
  tables: TableState,
  outstandingBalanceInfo: OutstandingBalanceInfoState,
}>;

export type Actions = LoadingActions<LoadingKeys> |
                      NotificationsActions |
                      ErrorsActions |
                      ManagerInfoActions |
                      UserInfoActions |
                      TabsActions;

export type CabinetStore = Store<State, Actions>;

export const mapCabinetToState: () => State = () => ({
  form: initFormState({
    [PAYMENT_METHOD_FORM]: {
      isEdit: true,
      paymentMethodId: -1,
      paymentMethodName: "",
      accountNumber: "",
      bankAccountName: "",
      bankAddress: "",
      bankName: "",
      beAddress: "",
      beCountry: "",
      beName: "",
      bitcoin: "",
      capitalistWallet: "",
      genome: "",
      iban: "",
      paxumMail: "",
      paymentNote: "",
      payoneerWallet: "",
      payoutCountry: "",
      payPalWallet: "",
      paysera: "",
      qiwi: "",
      swiftCode: "",
      vatNumber: "",
      webmoneyWallet: "",
      yandex: "",
      usdt: "",
    },
    [USER_INFO_FORM]: {
      firstName: "",
      lastName: "",
      email: "",
      imType: "",
      imName: "",
      photo: "",
      topCountries: "",
    },
    [CONTACT_INFO_FORM]: {
      companyName: "",
      companyUrl: "",
      companyType: "",
      address: "",
      country: "",
      taxSSN: "",
    },
  }),
  loading: initLoadingState({
    [INFO_MAIN_POPUP]: true,
    [MANAGER_INFO]: true,
    [EVENTS_POSTBACK]: true,
    [MAIN_REPORT_TABLE]: false,
    [TRACKING_CODE_LOADING]: false,
    [AUTOLOGIN_FORM]: false,
  }),
  tabs: initTabsState({
    [PROFILE_PAGE]: {
      tabs: [
        USER_INFO_TAB,
        CONTACT_INFO_TAB,
        PAYMENT_METHODS_TAB,
      ],
      current: USER_INFO_TAB,
    },
    [REPORTS_PAGE]: {
      tabs: [
        MAIN_REPORT_TAB,
        TRANSACTION_REPORT_TAB,
      ],
      current: MAIN_REPORT_TAB,
    },
    [POSTBACKS_PAGE]: {
      tabs: [
        DEFAULT_POSTBACK_TAB,
        INFO_POSTBACKS_TAB,
      ],
      current: DEFAULT_POSTBACK_TAB,
    },
  }),
  tables: initTableState({
    [DASHBOARD_TABLE]: {
      filters: {
        preset: defaultPreset.value,
        dateFrom: defaultPreset.start,
        dateTo: defaultPreset.end,
      },
    },
    [PAYMENT_HISTORY_TABLE]: {
      filters: {
        dateFrom: defaultPaymentHistoryPreset.start,
        dateTo: defaultPaymentHistoryPreset.end,
      },
      fields: [
        "id",
        "created",
        "dateFrom",
        "dateTo",
        "paidTotal",
        "paidAt",
        "currency",
        "stateId",
      ],
    },
    [BEST_OFFERS_LIST]: {},
    [MAIN_REPORT_TABLE]: {
      sorting: {},
      filters: {
        // $FlowFixMe
        date: {
          from: defaultPresetReport.start,
          to: defaultPresetReport.end,
        },
        groupBy: "date",
      },
      fields: [
        "date",
        "clicks",
        "cpa_registrations",
        "cpa_confirms",
        "cpa_installs",
        "sales",
        "click2reg",
        "epc",
        "total_revenue",
      ],
    },
    [TRANSACTION_REPORT_TABLE]: {
      sorting: {},
      filters: {
        // $FlowFixMe
        date: {
          from: defaultPresetReport.start,
          to: defaultPresetReport.end,
        },
      },
      fields: [
        "date",
        "conversion_time",
        "conversion_name",
        "commission",
        "country",
        "subId",
        "platform",
        "status",
        "decline_reason",
        "clickId",
      ],
      pendingFields: [
        "date",
        "conversion_time",
        "conversion_name",
        "commission",
        "country",
        "subId",
        "platform",
        "status",
        "decline_reason",
        "clickId",
      ],
    },
  }),
  notifications: initNotificationsState(),
  errors: initErrorsState(),
  userInfo: initUserInfoState(),
  managerInfo: initManagerInfoState(),
  dictionaries: initDictionariesState(),
  eventsPostback: initEventsPostbackState(),
  trackingTools: initTrackingToolsState(),
  outstandingBalanceInfo: initOutstandingBalanceInfoState(),
});

const reducers: CombinedReducer<State, Actions> = combineReducers({
  form,
  loading,
  notifications,
  errors,
  userInfo,
  managerInfo,
  tabs,
  dictionaries,
  eventsPostback,
  trackingTools,
  tables,
  outstandingBalanceInfo,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): CabinetStore => {
  // eslint-disable-next-line no-underscore-dangle, flowtype/require-variable-type
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapCabinetToState(),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
