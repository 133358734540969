// @flow
import type { Environment } from "@fas/cpa-state-manager/services/auth";

const getEndpoints: ({ baseUrl: string, reCaptchaKey?: string }) => Environment = ({ baseUrl, reCaptchaKey = "" }) => ({
  baseUrl,
  reCaptchaKey,
  links: {
    blog: "https://together-ads.com/blog/ ",
    home: "/dashboard",
    notFound: "/notFound",
    restorePassword: "/restorePassword",
    login: "/login",
    logout: "/logout",
    dashboard: "/dashboard",
    reports: "/reports/:type",
    postbacks: "/postbacks/:type",
    userInfo: "/profile/user",
    userPaymentDetails: "/profile/payment",
    profile: "/profile/:page",
  },
  endpoints: {
    get: {
      getDropdownList: `${baseUrl}/api/v1/dropdown`,
      infoPostback: `${baseUrl}/api/v1/infoPostback`,
      defaultPostback: `${baseUrl}/api/v1/defaultPostback`,
    },
    post: {
      login: `${baseUrl}/api/v1/auth/login`,
      autologin: `${baseUrl}/api/v1/auth/autologin`,
      restorePassword: `${baseUrl}/api/v1/auth/restorePassword`,
      changePassword: `${baseUrl}/api/v1/auth/changePassword`,
      mainReportExport: `${baseUrl}/api/v1/reportMainExport`,
      transactionReportExport: `${baseUrl}/api/v1/reportTransactionExport`,
      payoutHistoryExport: `${baseUrl}/api/v1/payoutHistoryExport`,
    },
    put: {},
    delete: {},
    redirect: {},
  },
});

export default getEndpoints;
