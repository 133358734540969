/* eslint-disable import/max-dependencies */
// @flow
import {
  combineReducers,
  createStore,
  compose,
  applyMiddleware,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type {
  Action as NotificationsActions,
} from "@fas/ui-framework/lib/redux/actions/notifications";
import { initState as initErrorsState, reducer as errors } from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import type { State as ErrorsState } from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import type { Actions as ErrorsActions } from "@fas/ui-framework/lib/redux/actions/errors";
import form, {
  initFormState,
  type State as FormState,
} from "@fas/ui-framework/lib/redux/reducers/form";
import loading, { type State as LoadingState, initLoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import { type Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import {
  LOGIN_FORM,
  AUTOLOGIN_FORM,
  RESTORE_PASSWORD_FORM,
  CHANGE_PASSWORD_FORM,
} from "@fas/cpa-state-manager/redux/constants";
import Immutable from "immutable";
import createSagaMiddleware, { type SagaMiddleware } from "redux-saga";
import mainSaga from "./saga";

type FORMS_KEYS = typeof LOGIN_FORM
  | typeof AUTOLOGIN_FORM
  | typeof RESTORE_PASSWORD_FORM
  | typeof CHANGE_PASSWORD_FORM;

export type State = $ReadOnly<{
  form: FormState,
  loading: LoadingState<FORMS_KEYS>,
  notifications: NotificationsState,
  errors: ErrorsState,
}>;

export type Actions = LoadingActions<FORMS_KEYS> | NotificationsActions | ErrorsActions;
export type AuthStore = Store<State, Actions>;

const mapAuthToState: () => State = () => ({
  form: initFormState({
    [LOGIN_FORM]: {
      login: "",
      password: "",
    },
    [AUTOLOGIN_FORM]: {
      email: "",
    },
    [RESTORE_PASSWORD_FORM]: {
      email: "",
    },
    [CHANGE_PASSWORD_FORM]: {
      password: "",
      confirmation: "",
    },
  }),
  loading: initLoadingState({
    [LOGIN_FORM]: false,
    [AUTOLOGIN_FORM]: false,
  }),
  notifications: initNotificationsState(),
  errors: initErrorsState(),
});

const reducers: CombinedReducer<State, Actions> = combineReducers({
  form,
  loading,
  notifications,
  errors,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): AuthStore => {
  // eslint-disable-next-line no-underscore-dangle, flowtype/require-variable-type
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapAuthToState(),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
