/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Form } from "@fas/ui-core";
import withForm, { type Props as FormProps } from "@fas/ui-framework/lib/services/form/withForm";
import { Typography, Button, Box } from "@mui/material";
import { Helmet } from "react-helmet";
import { RESTORE_PASSWORD_FORM } from "@fas/cpa-state-manager/redux/constants";
import { EmailOutlined } from "@mui/icons-material";
import environment from "environment";
import { withStyles } from "@mui/styles";
import TextField from "../TextFieldForm";
import schema from "./validation";
import { getValidation } from "../../utils";
import { autologinStyles } from "../../styles";
import AuthLayout from "../AuthLayout";

const RestorePasswordForm: StatelessFunctionalComponent<FormProps> = withForm(Form);

type Props = {
  isLoading: boolean,
  onKeyPress: (SyntheticKeyboardEvent<*>) => mixed,
  onSave: () => mixed,
  classes?: { root: string, button: string, title: string },
};

const RestorePassword: StatelessFunctionalComponent<Props> = ({
  isLoading,
  onKeyPress,
  onSave,
  classes,
}) => (
  <>
    <Helmet>
      <title>Restore password</title>
    </Helmet>
    <AuthLayout>
      <Box className={classes?.root}>
        <RestorePasswordForm id={RESTORE_PASSWORD_FORM} validate={getValidation(schema)} onSave={onSave}>
          <Typography variant="h5" className={classes?.title}>
            Restore Password
          </Typography>
          <TextField
            name="email"
            label="Your Email"
            placeholder="Input your email"
            autoFocus
            StartIcon={<EmailOutlined />}
            onKeyPress={onKeyPress}
            inputProps={{ inputMode: "email" }}
          />
          <Button
            className={classes?.button}
            disabled={isLoading}
            data-testid="restore-password-save"
            fullWidth
            variant="standart"
            type="submit"
          >
            Restore Password
          </Button>
          <Button
            data-testid="redirect-login"
            variant="text"
            component="a"
            size="small"
            color="primary"
            sx={{ my: 1, mx: "auto", maxWidth: { md: 250 } }}
            href={environment.links.login}
          >
            Back to Log In
          </Button>
        </RestorePasswordForm>
      </Box>
    </AuthLayout>
  </>
);

export default withStyles(autologinStyles)(RestorePassword);
