// @flow
import React from "react";
import { createTheme } from "@mui/material";
import ArrowDown from "../icons/ArrowDown";
import CheckboxBlank from "../icons/CheckboxBlank";
import CheckboxChecked from "../icons/CheckboxChecked";

type BaseTheme = {
  breakpoints: {
    down: *,
    up: *,
  },
  zIndex: *,
  palette: {
    divider: string,
    error: *,
    common: {
      white: string,
      black: string,
    },
  },
  breakpoints: *,
  typography: *,
};

type OverrideTheme = {|
  spacing: (number) => number,
  theme: string,
  props: *,
  typography: *,
  shape: *,
  buttons: *,
  palette: {|
    mode: *,
    background: *,
    text: *,
    icons: *,
    gradient: {
      start: string,
      stop: string,
    },
    error: *,
    success: *,
    primary: *,
    transparent: {
      default: string,
      text: string,
      disabled: string,
      light: string,
      dark: string,
      main: string,
      full: string,
      contrast: string,
    },
  |},
  components: *,
  breakpoints: { values: { [string]: number }, [string]: (*, *) => * },
|};

export type DefaultTheme = {
  ...BaseTheme,
  ...OverrideTheme,
  palette: {
    ...$PropertyType<BaseTheme, "palette">,
    ...$PropertyType<OverrideTheme, "palette">,
  },
};

const breakpoints: * = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1444,
    xl: 1536,
  },
};

const colors = {
  main: "#FFF",
  primary: "#DDDF4B",
  secondary: "#DAE0E3",
  mutted: "rgba(217, 217, 217, 0.70)",
  dark: "#2D2E2F",
  transparent: "rgba(255, 255, 255, 0.1)",
  error: "#E32116",
  success: "#169873",
};

const theme: * = createTheme({
  breakpoints,
});

const themeDefaultObject: OverrideTheme = {
  theme: "default",
  props: {},
  spacing: (f: number): number => f * 10,
  breakpoints,
  typography: {
    fontFamily: "Wix Madefor Display",
    fontStyle: "normal",
    h1: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(50),
      lineHeight: 1.4,
    },
    h4: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(28),
      lineHeight: 1.428,
    },
    h5: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 1.555,
      [theme.breakpoints.up("md")]: {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(28),
        lineHeight: 1.428,
      },
    },
    h6: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 1.555,
    },
    button: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      padding: "8px 16px",
      color: colors.main,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      textTransform: "uppercase",
      textDecoration: "none",
    },
    mainButton: {
      fontWeight: 800,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      padding: "6px 16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      textTransform: "uppercase",
      backgroundColor: colors.primary,
      color: colors.dark,
      textDecoration: "none",
      border: `2px solid ${colors.dark}`,
      borderRadius: "0",
      "&:hover": {
        border: "2px solid #FEFFB8",
        boxShadow: "0px 4px 20px 0px rgba(221, 223, 75, 0.50)",
        backgroundColor: colors.primary,
      },
      "&:disabled": {
        color: colors.main,
        background: "rgba(218, 224, 227, 0.50)",
      },
    },
    outlinedButton: {
      fontWeight: 800,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      padding: "8px 16px",
      color: colors.main,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      textTransform: "uppercase",
      border: `2px solid ${colors.main}`,
      textDecoration: "none",
    },
    outlinedMainButton: {
      fontWeight: 800,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      padding: "8px 16px",
      color: colors.primary,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      textTransform: "uppercase",
      border: `2px solid ${colors.primary}`,
      textDecoration: "none",
      "&:hover": {
        background: "rgba(255, 255, 255, 0.1)",
      },
    },
    body: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
    bodyBold: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      fontWeight: 700,
    },
    bodySmall: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.333,
    },
    bodySmallBold: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.428,
      fontWeight: 700,
    },
    bodyExtraSmallBold: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.333,
      fontWeight: 700,
    },
    tableMob: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(9),
      lineHeight: 2,
    },
    tableMobBold: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(9),
      lineHeight: 2,
    },
  },
  shape: {
    borderRadius: 0,
    cardBorderRadius: 0,
  },
  buttons: {
    main: {},
    secondary: {},
  },
  palette: {
    mode: "dark",
    background: {
      default: "#000",
      light: "rgba(255, 255, 255, 0.10)",
    },
    text: {
      main: colors.main,
      primary: colors.primary,
      secondary: colors.secondary,
      mutted: colors.mutted,
      dark: colors.dark,
    },
    icons: {},
    gradient: {},
    error: {
      main: colors.error,
      light: colors.error,
      dark: colors.error,
    },
    primary: {
      main: colors.primary,
    },
    success: {
      main: colors.success,
      light: colors.success,
      dark: colors.success,
    },
    transparent: {
      default: "rgba(255, 255, 255, 0.8)",
      text: "rgba(255, 255, 255, 0.5)",
      disabled: "rgba(255, 255, 255, 0.38)",
      light: "rgba(255, 255, 255, 0.08)",
      dark: "rgba(255, 255, 255, 0.6)",
      main: "rgba(255, 255, 255, 0.1)",
      full: "rgba(255, 255, 255, 0)",
      contrast: "rgba(0, 0, 0, 0.65)",
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          wordBreak: "break-word",
          whiteSpace: "break-spaces",
        },
        standardSuccess: {
          backgroundColor: colors.success,
        },
        standardError: {
          backgroundColor: colors.error,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxHeight: "40vh",
          whiteSpace: "break-spaces",
          overflowWrap: "anywhere",
          overflowY: "auto",
          display: "flex",
          alignItems: "flex-start",
          padding: 20,
          border: "1px solid rgba(218, 224, 227, 0.40)",
          background: "#1a1a1a",
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "24px",
          color: colors.main,
          "& button": {
            marginLeft: 10,
            position: "sticky",
            top: 0,
          },
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInput-root": {
            marginTop: "0px",
          },
          "& .MuiFormHelperText-root.Mui-error": {
            color: colors.main,
            lineHeight: 1.333,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {},
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          border: "1px solid rgba(218, 224, 227, 0.40)",
          background: "rgba(0, 0, 0, 0.20)",
          backgroundBlendMode: "multiply",
          padding: "9px 8px",
          "WebkitTextFillColor": "initial",
          color: colors.main,

          "& .MuiInputAdornment-positionEnd": {
            margin: "0 10px 0 0",
          },
          "& .MuiInputAdornment-positionStart": {
            margin: "0 0 0 10px",
          },
          "&.Mui-disabled": {
            color: colors.main,
            ".MuiInputAdornment-positionStart, .MuiInputAdornment-positionEnd": {
              color: colors.mutted,
            },
          },
          "&.Mui-focused": {
            borderColor: colors.primary,
            color: colors.primary,
            "& input, & .MuiSvgIcon-root": {
              color: colors.primary,
            },
          },
          "&.Mui-error": {
            borderColor: colors.error,
            color: colors.error,
            "& .MuiInputAdornment-positionEnd, & .MuiInputAdornment-positionStart": {
              color: colors.error,
            },
          },
        },
        input: {
          fontSize: "14px",
          lineHeight: 1.42,
          padding: 0,
          "WebkitTextFillColor": "inherit",
          color: "inherit",
          "&.Mui-disabled": {
            cursor: "not-allowed",
            color: "inherit",
            "WebkitTextFillColor": "inherit",
          },
          "&::placeholder, &::-webkit-input-placeholder": {
            color: colors.mutted,
            opacity: "1",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: colors.mutted,
          margin: "4px 0 0",
          "&:empty": {
            display: "none",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard",
        IconComponent: () => (
          <ArrowDown />
        ),
      },
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "1.428",
          background: "rgba(0, 0, 0, 0.20)",
          color: colors.mutted,
          backgroundBlendMode: "multiply",
          border: "1px solid rgba(218, 224, 227, 0.40)",
          minWidth: "120px",
          "&:hover": {
            background: colors.transparent,
          },
          "& .MuiSvgIcon-root": {
            color: colors.main,
            userSelect: "none",
            pointerEvents: "none",
            width: "1em",
            height: "1em",
            display: "inline-block",
            flexShrink: "0",
            transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            fontSize: "1.5rem",
            position: "absolute",
            right: "7px",
            top: "calc(50% - .5em)",
          },
          "&.Mui-disabled": {
            color: colors.mutted,
            "& .MuiSvgIcon-root": {
              color: colors.mutted,
            },
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxBlank />,
        checkedIcon: <CheckboxChecked />,
      },
      styleOverrides: {
        root: {
          color: "transparent",
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "20px",
          marginRight: "8px",
          padding: "0",
          "&.Mui-checked": {
            color: "transparent",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "16px",
          },
          "& + .MuiTypography-root": {
            fontSize: "14px",
            lineHeight: "1.428",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: "0",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "transparent" },
          style: {
            background: "rgba(255, 255, 255, 0.08)",
            fontWeight: 700,
            paddingTop: "12px",
            paddingBottom: "12px",
            minWidth: "50px",
            "&:hover": {
              background: "rgba(255, 255, 255, 0.1)",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          "& .MuiTouchRipple": {
            background: "rgba(255, 255, 255, 0.1)",
          },
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          color: "rgba(255,255,255, 0.65)",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#000",
          "& .MuiMenu-list, & .MuiAutocomplete-listbox": {
            background: "rgba(255, 255, 255, 0.1)",
            color: "#FFF",
            "& .MuiMenuItem-root:hover, & .MuiAutocomplete-option:hover": {
              cursor: "pointer",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
            "& .Mui-selected, & li[aria-checked=true], & .Mui-selected:focus, & .Mui-selected:hover, & .Mui-focused:hover": {
              backgroundColor: colors.primary,
              color: colors.dark,
            },
          },
          "& .MuiMenuItem-root": {
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "1.428",
            minHeight: "auto",
          },
        },
      },
    },
    MuiTabsSwitcher: {
      styleOverrides: {
        root: {
          border: "none",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: "none",
        },
        root: {
          minHeight: "auto",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: theme.typography.pxToRem(16),
          lineHeight: 1.5,
          color: colors.mutted,
          padding: "8px 16px",
          minHeight: "auto",
          minWidth: "auto",
          textTransform: "none",
          border: "none",

          "&.Mui-selected": {
            color: colors.dark,
            backgroundColor: `${colors.primary} !important`,
            fontWeight: 700,
          },
          "&:hover": {
            background: colors.transparent,
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "& label": {
            color: "#FFF",
          },
          "& label.Mui-focused": {
            color: "#FFF",
            fontWeight: 700,
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        ul: {
          gap: "8px",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          height: "40px",
          width: "40px",
          padding: "8px",
          fontSize: theme.typography.pxToRem(16),
          lineHeight: 1,
          fontWeight: 600,
          color: colors.primary,
          border: `2px solid ${colors.primary}`,
          margin: 0,
          borderRadius: "0",
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: colors.primary,
            color: colors.dark,
          },
          "&:hover": {
            backgroundColor: "rgba(221, 223, 75, 0.20)",
            color: colors.main,
          },
        },
      },
    },
    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          "&:first-child": {
            [theme.breakpoints.up("sm")]: {
              paddingRight: "20px",
            },
            [theme.breakpoints.up("md")]: {
              padding: "0",
            },
          },
          "& + .MuiBox-root": {
            padding: "0 0 0 10px",
            "& .MuiButtonBase-root": {
              fontSize: "12px",
              lineHeight: 1.3333,
              color: colors.mutted,
              "&:hover": {
                color: colors.dark,
                background: colors.primary,
              },
            },
          },
          "& .MuiPickerStaticWrapper-content": {
            minWidth: "220px",
          },
          "& .MuiCalendarOrClockPicker-root > div": {
            width: "auto",
          },
          "& .MuiCalendarPicker-root": {
            width: "unset",
            maxHeight: "unset",
          },
          "& .MuiDayPicker-slideTransition": {
            minHeight: "195px",
            [theme.breakpoints.up("md")]: {
              minHeight: "175px",
            },
          },
          "& .MuiPickersCalendarHeader-root": {
            padding: "0",
            margin: "0 0 24px",
            minHeight: "auto",
            maxHeight: "none",
          },
          "& .MuiPickersCalendarHeader-label": {
            fontSize: "12px",
            fontWeight: "700",
            lineHeight: 1.3333,
          },
          "& .MuiPickersArrowSwitcher-spacer": {
            width: "20px",
          },
          "& .MuiPickersArrowSwitcher-button": {
            padding: 0,
            "& .MuiSvgIcon-root": {
              width: "16px",
              height: "16px",
            },
          },
          "& .MuiDayPicker-weekDayLabel": {
            margin: "0 5px",
            width: "28px",
            height: "initial",
            color: colors.mutted,
            fontSize: theme.typography.pxToRem(10),
            lineHeight: 1.2,
            [theme.breakpoints.up("md")]: {
              margin: "0 2px",
              width: "24px",
            },
          },
          "& .MuiPickersDay-root": {
            fontWeight: "400",
            fontSize: theme.typography.pxToRem(10),
            lineHeight: 1.2,
            color: "rgba(221, 223, 75, 0.65)",
            height: "28px",
            width: "28px",
            background: colors.transparent,
            margin: "0 5px",
            "&.Mui-selected": {
              background: "#9DCBF1",
              color: colors.dark,
            },
            [theme.breakpoints.up("md")]: {
              height: "24px",
              width: "24px",
              margin: "0 2px",
            },
          },
          "& .MuiDayPicker-weekContainer": {
            margin: "10px 0",
            "&:first-of-type": {
              marginTop: "5px",
            },
            [theme.breakpoints.up("md")]: {
              margin: "4px 0",
            },
          },
          "& .MuiYearPicker-root": {
            gap: "4px",
            padding: 0,
            "& .PrivatePickersYear-root": {
              flexBasis: "unset",
            },
            "& button": {
              padding: "6px 4px",
              margin: "0",
              height: "auto",
              background: "rgba(255, 255, 255, 0.10)",
              color: "rgba(221, 223, 75, 0.65)",
              fontSize: "12px",
              lineHeight: 1.3333,
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.08)",
              },
              "&.Mui-selected": {
                background: "#9DCBF1",
                color: colors.dark,
              },
            },
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: "transparent",
          boxShadow: "none",
          border: "none",
          "&.Mui-expanded": {
            margin: "0",
          },
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          minHeight: "unset",
          "&.Mui-expanded": {
            minHeight: "unset",
            marginBottom: "8px",
            [theme.breakpoints.up("md")]: {
              marginBottom: "12px",
            },
          },
        },
        content: {
          margin: 0,
          "&.Mui-expanded": {
            margin: "0",
          },
          "& .MuiTypography-root": {
            fontSize: theme.typography.pxToRem(16),
            fontWeight: 700,
            lineHeight: 1.5,
            color: colors.main,
            [theme.breakpoints.up("md")]: {
              fontSize: theme.typography.pxToRem(18),
              fontWeight: 700,
              lineHeight: 1.555,
            },
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "0",
          "& .MuiInput-root, & .MuiCoreUi-wrapperDatePicker .MuiInputBase-root": {
            background: colors.transparent,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          border: "none",
        },
        tooltip: {
          margin: 0,
          padding: "8px",
          background: "#000",
          boxShadow: "0px 0px 40px 0px rgba(221, 223, 75, 0.40)",
          "& .MuiIconButton-root": {
            padding: 0,
            marginLeft: "12px",
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 400,
            lineHeight: 1.333,
          },
          "& .MuiSvgIcon-root": {
            fontSIze: "24px",
          },
        },
      },
    },
  },
};

export default themeDefaultObject;
