/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Form } from "@fas/ui-core";
import withForm, { type Props as FormProps } from "@fas/ui-framework/lib/services/form/withForm";
import {
  Box,
} from "@mui/material";
import {
  EmailOutlined,
  Language,
  LockOutlined,
} from "@mui/icons-material";
import { Helmet } from "react-helmet";
import { withStyles } from "@mui/styles";
import { USER_INFO_FORM } from "../../constants";
import TextField from "../TextFieldForm";
import AccountUser from "../../icons/AccountUser";
import Person from "../../icons/Person";
import Chat from "../../icons/Chat";
import AccountBox from "../../icons/AccountBox";
import { formGridStyles } from "../../styles";

const UserInfoForm: StatelessFunctionalComponent<FormProps> = withForm(Form);

export type Props = {};

const Layout = withStyles(formGridStyles)(({ children, classes }) => (
  <Box className={classes.root}>{children}</Box>
));

const UserInfo: StatelessFunctionalComponent<Props> = () => {
  const tooltipIconProps = {
    EndIcon: <LockOutlined />,
    slotProps: {
      tooltip: {
        title: "For any updates in this field, please contact your account manager.",
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Personal info</title>
      </Helmet>
      <UserInfoForm id={USER_INFO_FORM}>
        <Box>
          <Layout>
            <TextField
              {...tooltipIconProps}
              name="firstName"
              label="First Name"
              placeholder="What is your first name?"
              StartIcon={<AccountUser />}
              disabled
            />
            <TextField
              {...tooltipIconProps}
              name="lastName"
              label="Last Name"
              placeholder="What is your last name?"
              StartIcon={<Person />}
              disabled
            />
            <Box className="colSpan2">
              <TextField
                name="email"
                label="Email"
                placeholder="What is your email?"
                StartIcon={<EmailOutlined />}
                disabled
                EndIcon={<LockOutlined />}
                slotProps={{
                  tooltip: {
                    title: "For any updates in this field, please contact your account manager.",
                  },
                }}
              />
            </Box>

            <TextField
              {...tooltipIconProps}
              name="imType"
              label="Messenger"
              placeholder="Select your messenger"
              StartIcon={<Chat />}
              disabled
            />

            <TextField
              {...tooltipIconProps}
              name="imName"
              label="Messenger ID"
              placeholder="What is your messenger ID?"
              StartIcon={<AccountBox />}
              disabled
            />
            <TextField
              {...tooltipIconProps}
              name="topCountries"
              label="Top GEOs"
              placeholder="What are the top GEOs you work with?"
              StartIcon={<Language />}
              disabled
            />
          </Layout>
        </Box>
      </UserInfoForm>
    </>
  );
};

export default UserInfo;
